// form
import { useFormContext, Controller, FieldValues } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = FieldValues & {
  name: string;
};
export default function RHFHidden({
  name,
  ...other
}: Props) {
  const {
    control
  } = useFormContext();
  return <Controller name={name} control={control} render={({
    field,
    fieldState: {
      error
    }
  }) => <input type="hidden" {...field} {...other} />} data-sentry-element="Controller" data-sentry-component="RHFHidden" data-sentry-source-file="RHFHidden.tsx" />;
}