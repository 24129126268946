import { FormProvider as Form, UseFormReturn } from 'react-hook-form';
type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};
function FormProvider({
  children,
  onSubmit,
  methods
}: Props) {
  return <Form {...{
    ...methods,
    onSubmit
  }} data-sentry-element="Form" data-sentry-component="FormProvider" data-sentry-source-file="FormProvider.tsx">
      {children}
    </Form>;
}
export default FormProvider;